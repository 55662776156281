(function () {
    'use strict';

    angular.module('informaApp')
        .component('tabs', {
            templateUrl: 'components/tabs/template.html',
            controller: ['$element', '$timeout', TabsController],
            bindings: {
                activeTabId: '<',
                source: '<'
            }
        });

    function TabsController($element, $timeout) {
        var vm = this;

        $timeout(function () {
            $element.find('.nav-tabs').scrollingTabs();
        });

        this.$onChange = function (changes) {
            if (changes.activeTabId) {
                toggleActiveTab(changes.activeTabId.currentValue);
            }
        };

        function toggleActiveTab(id) {
            var classValue = 'active';

            $element.find('ul.nav-tabs li')
                .removeClass(classValue)
                .find('a[href="#' + id + '"]')
                .parent()
                .addClass(classValue);
        }
    }
})();